// NODE_ENV=production
export const REACT_APP_NODE_ENV = "development";
export const APP_PORT = 3000;

// Blockchain network
export const REACT_APP_BLOCKCHAIN_NETWORK = "TESTNET";
export const REACT_APP_BLOCKCHAIN_NETWORK_MAINNET = "MAINNET";
export const REACT_APP_BLOCKCHAIN_RPC_NETWORK =
  "https://data-seed-prebsc-1-s1.binance.org:8545/";
export const REACT_APP_BLOCKCHAIN_RPC_NETWORK_MAINNET =
  "https://bsc-dataseed.binance.org/";
// REACT_APP_BLOCKCHAIN_RPC_NETWORK="https://rpc.testnet.fantom.network/"
// REACT_APP_BLOCKCHAIN_RPC_NETWORK_MAINNET="https://rpcapi.fantom.network"

// config wallet connect
// BLOCKCHAIN_NETWORK_WALLETCONNECT="TESTNET"
export const BLOCKCHAIN_NETWORK_WALLETCONNECT = "MAINNET";

// Token
export const REACT_APP_BNB_TOKEN = 0x0000000000000000000000000000000000000000;
// EFUN_TOKEN=0x6746e37a756da9e34f0bbf1c0495784ba33b79b4
// DEV
export const REACT_APP_EFUN_TOKEN =
  "0x63e561007080a945225bc885653d7e704851298a";

// DFY_TOKEN=0xd98560689c6e748dc37bc410b4d3096b1aa3d8c2
// dev
export const REACT_APP_DFY_TOKEN = 0x96a73c884b6577227bf66fa7ada3e19fd8196324;

// "0x6746e37a756da9e34f0bbf1c0495784ba33b79b4"
// EFUN_DEV_TOKEN="0x63e561007080a945225bc885653d7e704851298a"

// Contract
export const REACT_APP_GROUP_PREDICT_CONTRACT =
  "0x0232F368050F1D3E4B20B9548A7009CA357B699F";
export const GROUP_PREDICT_CONTRACT_MAINNET =
  "0x2252c9CF49B4e7ADd701c433613aa5E315172848";

export const REACT_APP_MATCH_CONTRACT =
  "0x2FA4872858ECc4CAfAe2eBb1F56399E0746eB48B";
export const REACT_APP_MATCH_CONTRACT_MAINNET =
  "0x33C85f1ecd2B0Dc5fBAe1FABAA042E291dd19FD3";

export const REACT_APP_SPONSOR_MATCH_DEV =
  "0x90BaD634cb1B316F568c454aB8605A62D9670E2e";
export const REACT_APP_SPONSOR_PREDICT_DEV =
  "0xCfb42B7FEafab87Abd67be84681F3F9B05574bd9";

// HISTORY API
export const REACT_APP_HISTORY = "http://206.189.82.63:9000";
export const REACT_APP_HISTORY_MAINNET =
  "https://api.thegraph.com/subgraphs/name/eurogamesfun/egamesfun";
//
// PER_2_PER_API='https://efun.datsan247.com/api/v1/p2p'
// PER_2_PER_API='https://a.efun.tech/api/v1/p2p'
export const REACT_APP_API_HOST = "https://efun.datsan247.com";
// API_HOST='https://a.efun.tech'

export const REACT_APP_VUE_APP_MAX_INT_STAKING =
  "115792089237316195423570985008687907853269984665640564039457584007913129639935";
